import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Block, SectionHeader, SectionCopy } from '../../components';
import { breakpoints } from '../../styles';
import DurianImage from './DurianImage';
import BananaImage from './BananaImage';
import OilPalmImage from './OilPalmImage';

const Section = styled.section``;

const Title = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 2rem 0 0;
  @media (min-width: ${breakpoints.medium}px) {
    text-align: left;
  }
`;

const Table = styled.table`
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  border-collapse: separate;
  font-family: 'Metropolis';

  thead th {
    font-weight: 600;
    text-align: left;
  }

  thead th:first-of-type,
  tbody td:first-of-type {
    width: 300px;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: rgba(243, 244, 246, 1);
  }

  tr:nth-of-type(1) {
  }

  thead th,
  tbody td {
    vertical-align: top;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 200px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border: 1px solid rgba(156, 163, 175, 0.5);
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 280px;
  img {
    object-fit: cover;
  }
  margin-bottom: 2rem;
`;

const Content = () => {
  //
  return (
    <Section>
      <Container modifiers={['tightMargin']}>
        <Block>
          <ImageContainer>
            <DurianImage />
          </ImageContainer>
          <Title>
            <SectionHeader modifiers={['small']}>
              <h2>Durian</h2>
            </SectionHeader>
            <SectionCopy>Product Recommendations for Durian Cultivation.</SectionCopy>
          </Title>
          <div style={{ marginBottom: '4rem' }}>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Stages</th>
                    <th>Usage</th>
                    <th>Frequency</th>
                    <th>Mixture</th>
                    <th>Method</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CEN Vital-X Fertilizer (CVX30)</td>
                    <td>Fertilizer & Enhancer</td>
                    <td>
                      The CVX fertilizer is a bio-chemical fertilizer and enhancer suitable for all fruit trees. When
                      used regularly it will provide nutrients to help plants recover, grow and flourish.
                    </td>
                    <td>Starting from 70 to 80% completion of harvesting.</td>
                    <td>1 litre CVX30 Fertilizer will treat 70 trees.</td>
                    <td>Once every 2 to 3 months.</td>
                    <td>120ml of CVX30 into 16 litres water to spray 8 trees (2 litres solution per tree).</td>
                    <td>Spray onto tree trunk and branches.</td>
                  </tr>
                  <tr>
                    <td>EKOCEN-19</td>
                    <td>Organic Insect Repellent</td>
                    <td>
                      EKOCEN-19 is an organic insect repellent. It helps repel insect that are pest to fruit trees.
                    </td>
                    <td>After CVX application, when trees sprout new leaves or anytime when there is insect attack.</td>
                    <td>1 bottle (140ml) EKOCEN-19 will treat 100 trees.</td>
                    <td>Best applied with CVX30 Fertilizer application or monthly.</td>
                    <td>10ml of EKOCEN-19 into 16 litres water to spray 8 trees.</td>
                    <td>Spray onto leaves, branches, or trunk.</td>
                  </tr>
                  <tr>
                    <td>GEOLYT</td>
                    <td>Soil Detoxifier</td>
                    <td>
                      GEOLYT is a soil detoxifier. It increases electro conductivity of the soil which improves
                      fertilizer absorption.
                    </td>
                    <td>After harvesting is best. Second application at 6 months later.</td>
                    <td>1 litre GEOLYT will treat 500 mature trees.</td>
                    <td>Once or twice a year.</td>
                    <td>20ml of GEOLYT into 16 litres water to spray 8 trees (2 litres solution per tree).</td>
                    <td>Spray onto soil under tree canopy.</td>
                  </tr>
                  <tr>
                    <td>EXCELERITE</td>
                    <td>Multi-Minerals and Trace Minerals</td>
                    <td>
                      EXCELERITE is an organic multi-mineral that contains 78 types of different elements. It is used to
                      supplement mineral deficiencies in the soil.
                    </td>
                    <td>After harvesting is best. Second application at 6 months later.</td>
                    <td>1 to 2 kg EXCELERITE per tree.</td>
                    <td>Once or twice a year.</td>
                    <td>-</td>
                    <td>Broadcast under the canopy of trees.</td>
                  </tr>
                  <tr>
                    <td>GEAR-10</td>
                    <td>Bio-Stimulant</td>
                    <td>
                      GEAR-10 is a bio stimulant to reduce plant stress. It will therefore reduce flower abortion and
                      increase yield.
                    </td>
                    <td>Start before or at flowering.</td>
                    <td>50gm Gear-10 will treat 1 acre durian trees.</td>
                    <td>1 or 2 times per season. At flowering and one month later.</td>
                    <td>50gm Gear-10 into 50 to 100 litres water (1 to 2 litres solution per tree).</td>
                    <td>Spray onto leaves or soil.</td>
                  </tr>
                  <tr>
                    <td>CEN-4 & CEN-20 Combination</td>
                    <td>Plant Vitamins</td>
                    <td>
                      CEN-4 fertilizer promotes fruit growth and even ripening. CEN-20 fertilizer promotes more
                      flowering and fruit conversion.
                    </td>
                    <td>Start just before flowering until fruit is fully developed.</td>
                    <td>70ml CEN-4 + 70ml CEN-20 will treat 100 trees.</td>
                    <td>Once every 2 weeks.</td>
                    <td>
                      5ml of CEN-4 and 5ml of CEN-20 into 16 litres water to spray 8 trees (2 litres solution per tree).
                    </td>
                    <td>Spray onto leaves, branches, or trunk.</td>
                  </tr>
                  <tr>
                    <td>EKOCEN-24</td>
                    <td>Organic Fungal Repellent</td>
                    <td>
                      EKOCEN-24 is an organic anti fungus. It helps to prevent fungal infection when used regularly.
                    </td>
                    <td>Spray during dry season to increase resistance against fungus. </td>
                    <td>1 bottle (140 ml) EKOCEN-24 will treat 100 trees.</td>
                    <td>3 to 4 times per season. Monthly.</td>
                    <td>10ml of EKOCEN-24 into 16 litres water to spray 8 trees.</td>
                    <td>Spray onto leaves, branches, or trunk.</td>
                  </tr>
                  <tr>
                    <td>BIOCOMBAT</td>
                    <td>Organic Pesticide</td>
                    <td>
                      BIOCOMBAT is an organic pesticide. It helps to control and terminate insect attacks by using
                      organic means.
                    </td>
                    <td>Spray as treatment or for prevention</td>
                    <td>-</td>
                    <td>For treatment, spray once every 3 days. For prevention, spray once every 7 to 10 days.</td>
                    <td>16ml of BIOCOMBAT into 16 litres water.</td>
                    <td>Spray onto leaves and branches.</td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
          </div>

          <ImageContainer>
            <BananaImage />
          </ImageContainer>

          <Title>
            <SectionHeader modifiers={['small']}>
              <h2>Banana</h2>
            </SectionHeader>
            <SectionCopy>Product Recommendations for Banana Cultivation.</SectionCopy>
          </Title>
          <div style={{ marginBottom: '4rem' }}>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Stages</th>
                    <th>Usage</th>
                    <th>Frequency</th>
                    <th>Mixture</th>
                    <th>Method</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CEN Vital-X Fertilizer (CVX30)</td>
                    <td>Fertilizer and Enhancer</td>
                    <td>
                      The CVX fertilizer is a bio-chemical fertilizer and enhancer suitable for all fruit trees. When
                      used regularly it will provide nutrients to help plants recover, grow and flourish.
                    </td>
                    <td>From planting.</td>
                    <td>1 litre CVX30 fertilizer will treat 4,170 trees.</td>
                    <td>Once a month.</td>
                    <td>250ml of CVX30 into 16 litres water (60ml solution per tree).</td>
                    <td>Spray onto tree trunk and roots.</td>
                  </tr>
                  <tr>
                    <td>GEOLYT</td>
                    <td>Soil Detoxifier</td>
                    <td>
                      GEOLYT is a soil detoxifier. It increases electro conductivity of the soil which improves
                      fertilizer absorption.
                    </td>
                    <td>Anytime</td>
                    <td>1 litre GEOLYT will treat 1 hectare</td>
                    <td>Twice a year.</td>
                    <td>60ml of GEOLYT into 16 litres water.</td>
                    <td>Spray onto soil around the trees.</td>
                  </tr>
                  <tr>
                    <td>EXCELERITE</td>
                    <td>Multi-Minerals and Trace Minerals</td>
                    <td>
                      EXCELERITE is an organic multi-mineral that contains 78 types of different elements. It is used to
                      supplement mineral deficiencies in the soil.
                    </td>
                    <td>Best at planting or at early stages of planting.</td>
                    <td>20g EXCELERITE per tree.</td>
                    <td>Once or twice a year.</td>
                    <td>-</td>
                    <td>Broadcast at the base of the trees.</td>
                  </tr>
                  <tr>
                    <td>GEAR-10</td>
                    <td>Bio-Stimulant</td>
                    <td>
                      GEAR-10 is a bio stimulant to reduce plant stress. It will therefore reduce flower abortion and
                      increase yield.
                    </td>
                    <td>Start before or at flowering.</td>
                    <td>50gm Gear-10 will treat 1 acre.</td>
                    <td>1 or 2 times per season. At flowering and one month later.</td>
                    <td>50gm Gear-10 into 50 to 100 litres water (1 litre solution per tree).</td>
                    <td>Spray onto roots at the base of the plant.</td>
                  </tr>
                  <tr>
                    <td>CEN-4 & CEN-7 Combination</td>
                    <td>Plant Vitamins</td>
                    <td>
                      CEN-4 fertilizer promotes fruit growth and sweetness. CEN-7 fertilizer promotes healthy growth.
                    </td>
                    <td>Start just before flowering until fruit is fully developed.</td>
                    <td>70ml CEN-4 + 70ml CEN-7 will treat 1 hectare.</td>
                    <td>Once every 2 weeks.</td>
                    <td>5ml of CEN-4 and 5ml of CEN-7 into 16 litres water (160ml solution per tree).</td>
                    <td>Spray onto leaves, branches, or trunk.</td>
                  </tr>
                  <tr>
                    <td>EKOCEN-24</td>
                    <td>Organic Fungal Repellent</td>
                    <td>
                      EKOCEN-24 is an organic anti fungus. It helps to prevent fungal infection when used regularly.
                    </td>
                    <td>Spray during dry season to increase resistance against fungus. </td>
                    <td>1 bottle (140 ml) EKOCEN-24 will treat 1 hectare.</td>
                    <td>Monthly.</td>
                    <td>10ml of EKOCEN-24 into 16 litres water (160ml solution per tree).</td>
                    <td>Spray onto leaves, branches, or trunk.</td>
                  </tr>
                  <tr>
                    <td>BIOCOMBAT</td>
                    <td>Organic Pesticide</td>
                    <td>
                      BIOCOMBAT is an organic pesticide. It helps to control and terminate insect attacks by using
                      organic means.
                    </td>
                    <td>Spray as treatment or for prevention</td>
                    <td>-</td>
                    <td>For treatment, spray once every 3 days. For prevention, spray once every 7 to 10 days.</td>
                    <td>16ml of BIOCOMBAT into 16 litres water.</td>
                    <td>Spray onto leaves and branches.</td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
          </div>

          <ImageContainer>
            <OilPalmImage />
          </ImageContainer>

          <Title>
            <SectionHeader modifiers={['small']}>
              <h2>Oil Palm</h2>
            </SectionHeader>
            <SectionCopy>Product Recommendations for Oil Palm Cultivation.</SectionCopy>
          </Title>
          <div>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Stages</th>
                    <th>Usage</th>
                    <th>Frequency</th>
                    <th>Mixture</th>
                    <th>Method</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CEN Vital-X Fertilizer (CVX30)</td>
                    <td>Fertilizer & Enhancer</td>
                    <td>
                      The CVX fertilizer is a bio-chemical fertilizer and enhancer suitable for all fruit trees. When
                      used regularly it will provide nutrients to help plants recover, grow and flourish.
                    </td>
                    <td>For mature palms. To improve fruit quality and yield.</td>
                    <td>1 litre CVX30 fertilizer will treat 70 trees.</td>
                    <td>Once every 2 to 3 months.</td>
                    <td>150 to 200ml CVX30 into 16 litres water to spray 10 trees (1.5 litres solution per tree).</td>
                    <td>Spray onto roots at base of tree trunk.</td>
                  </tr>
                  <tr>
                    <td>EKOCEN Vital-X Fertilizer (EVX-24)</td>
                    <td>Fertilizer, Enhancer and Fungal Resistance</td>
                    <td>
                      The EVX fertilizer is a bio-chemical fertilizer/enhancer suitable for all fruit trees. When used
                      regularly it will provide nutrients to help plants recover from fungus infection, for growth and
                      yielding.
                    </td>
                    <td>For mature palms to improve plant’s resistance to fungus.</td>
                    <td>1 litre EVX-24 fertilizer will treat 70 trees.</td>
                    <td>Once every 2 to 3 months.</td>
                    <td>150 to 200ml EVX-24 into 16 litres water to spray 10 trees (1.5 litres solution per tree).</td>
                    <td>Spray onto roots at base of tree trunk.</td>
                  </tr>
                  <tr>
                    <td>EKOCEN Vital-X Fertilizer (EVX-19) </td>
                    <td>Fertilizer, Enhancer and Insect Repellent</td>
                    <td>
                      The EVX fertilizer is a bio-chemical fertilizer/enhancer suitable for all fruit trees. When used
                      regularly it will provide nutrients to help repel insect attacks, for growth and yielding.
                    </td>
                    <td>For mature palms to improve plant’s resistance to bagworm/insects.</td>
                    <td>1 litre EVX-19 fertilizer will treat 70 trees.</td>
                    <td>Once every 2 to 3 months.</td>
                    <td>150 to 200ml EVX-19 into 16 litres water to spray 10 trees (1.5 litres solution per tree).</td>
                    <td>Spray onto roots at base of tree trunk.</td>
                  </tr>
                  <tr>
                    <td>CEN Vital-X Fertilizer (CVX-9)</td>
                    <td>Fertilizer, Enhancer for Growth</td>
                    <td>
                      The CVX fertilizer is a bio-chemical fertilizer/enhancer suitable for all fruit trees. When used
                      regularly it will provide nutrients to help plants grow healthily and fast.
                    </td>
                    <td>For young and nursery palms.</td>
                    <td>1 litre CVX-9 fertilizer will treat 140 trees.</td>
                    <td>Once every 2 months.</td>
                    <td>240ml CVX into 16 litres water to spray 32 trees (500ml solution per tree).</td>
                    <td>Spray onto roots at base of tree trunk.</td>
                  </tr>
                  <tr>
                    <td>GEOLYT</td>
                    <td>Soil Detoxifier</td>
                    <td>
                      GEOLYT is a soil detoxifier. It increases electro conductivity of the soil which improves
                      fertilizer absorption.
                    </td>
                    <td>Anytime</td>
                    <td>1 litre GEOLYT will treat 500 mature trees.</td>
                    <td>Once to twice a year.</td>
                    <td>60ml of GEOLYT into 16 litres water to spray 32 trees circles (500ml solution per tree).</td>
                    <td>Spray onto soil around the tree.</td>
                  </tr>
                  <tr>
                    <td>GEAR-10</td>
                    <td>Bio-Stimulant</td>
                    <td>
                      GEAR-10 is a bio stimulant to reduce plant stress. It will therefore reduce flower abortion and
                      increase yield.
                    </td>
                    <td>Anytime</td>
                    <td>50gm Gear-10 will treat 1 acre.</td>
                    <td>100gm Gear-10 will treat 1 hectare.</td>
                    <td>25gm of Gear-10 into 16 litres water to spray 32 trees (500ml solution per tree).</td>
                    <td>Spray onto roots at palm base.</td>
                  </tr>
                  <tr>
                    <td>BIOCOMBAT</td>
                    <td>Organic Pesticide</td>
                    <td>
                      BIOCOMBAT is an organic pesticide. It helps to control and terminate insect attacks by using
                      organic means.
                    </td>
                    <td>Spray as treatment or for prevention.</td>
                    <td>-</td>
                    <td>For treatment, spray once every 3 days. For prevention, spray once every 7 to 10 days.</td>
                    <td>16ml of BIOCOMBAT into 16 litres water.</td>
                    <td>Spray onto leaves and branches.</td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
          </div>
        </Block>
      </Container>
    </Section>
  );
};

export default Content;
