import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Content from '../page-components/crops/Content';

const Crops = ({ location: { pathname } }) => (
  <>
    <SEO title="Crops" description="Crops" />
    <Content />
  </>
);

export default Crops;

Crops.propTypes = {
  location: PropTypes.object.isRequired,
};
